<script>
  import { onMount } from "svelte"
  import PodcastForm from "./PodcastForm.svelte"
  import { apiKey, page } from "./store.js"

  let podcasts = []

  onMount(async () => {
    try {
      const res = await fetch("API_URL/podcasts", {
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      podcasts = await res.json()
    } catch (error) {
      alert(error.message)
    }
  })

  async function destroy(podcast) {
    if (
      !confirm(`Voulez-vous vraiment supprimer le podcast "${podcast.title}" ?`)
    )
      return

    try {
      const res = await fetch("API_URL/podcasts/" + podcast.id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      podcasts = podcasts.filter((el) => el.id !== podcast.id)
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>Podcasts</h1>

<div class="my">
  <button on:click={() => ($page = { component: PodcastForm })}>
    + Ajouter un podcast
  </button>
</div>

<table class="my">
  <thead>
    <tr>
      <th>Titre</th>
      <th>YouTube ID</th>
      <th>Publié le</th>
      <th />
    </tr>
  </thead>
  <tbody>
    {#each podcasts as podcast}
      <tr>
        <td>{podcast.title}</td>
        <td>{podcast.youtubeId}</td>
        <td class:pending={new Date(podcast.publishedAt) > new Date()}>
          {new Date(podcast.publishedAt).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </td>
        <td>
          <button
            class="secondary"
            on:click={() =>
              ($page = {
                component: PodcastForm,
                data: { podcastId: podcast.id },
              })}
          >
            ✎ Modifier
          </button>
          <button class="danger" on:click={() => destroy(podcast)}>×</button>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
