<script>
  import { onMount } from "svelte"
  import RichText from "./RichText.svelte"
  import SaturdayLessons from "./SaturdayLessons.svelte"
  import { apiKey, page } from "./store.js"
  import { datetimeForInput } from "./utils.js"

  const id = $page.data?.saturdayLessonId
  let title
  let subtitle
  let firstLesson = datetimeForInput()
  let lastLesson = null
  let exam = null
  let location = null
  let text

  onMount(async () => {
    try {
      if (id) {
        const res = await fetch("API_URL/saturday-lessons/" + id)
        if (!res.ok) throw await res.json()
        const saturdayLesson = await res.json()

        title = saturdayLesson.title
        subtitle = saturdayLesson.subtitle
        firstLesson = datetimeForInput(saturdayLesson.firstLesson)
        lastLesson = saturdayLesson.lastLesson
          ? datetimeForInput(saturdayLesson.lastLesson)
          : null
        exam = saturdayLesson.exam
          ? datetimeForInput(saturdayLesson.exam)
          : null
        location = saturdayLesson.location
        text = saturdayLesson.text
      }
    } catch (error) {
      alert(error.message)
    }
  })

  async function submit() {
    if (!title) return alert("Le titre est requis")

    try {
      const res = await fetch(`API_URL/saturday-lessons${id ? "/" + id : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify({
          title,
          subtitle,
          firstLesson: new Date(firstLesson),
          lastLesson: lastLesson ? new Date(lastLesson) : null,
          exam: exam ? new Date(exam) : null,
          location,
          text,
        }),
      })
      if (!res.ok) throw await res.json()
      $page = { component: SaturdayLessons }
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>
  {#if id}Modification d'un cours du samedi{:else}Nouveau cours du samedi{/if}
</h1>

<label>
  Titre<br />
  <input bind:value={title} />
</label>

<label>
  Sous-titre<br />
  <input bind:value={subtitle} />
</label>

<label>
  Premier cours<br />
  <input id="firstLesson" type="datetime-local" bind:value={firstLesson} />
</label>

<label>
  Dernier cours<br />
  <input id="lastLesson" type="datetime-local" bind:value={lastLesson} />
</label>

<label>
  Date de l'examen<br />
  <input id="exam" type="datetime-local" bind:value={exam} />
</label>

<label>
  Localisation<br />
  <select bind:value={location}>
    <option value="ibb">IBB</option>
    <option value="lille">Lille</option>
  </select>
</label>

<label for="text">
  Texte<br />
  <RichText bind:value={text} />
</label>

<hr />

<button on:click={submit}>
  {id ? "✎ Modifier le cours du samedi" : "+ Ajouter le cours du samedi"}
</button>
