<script>
  import { onMount } from "svelte"
  import MediaUploader from "./MediaUploader.svelte"
  import { apiKey } from "./store.js"

  let calendarFr
  let calendarFrPrevious
  let calendarEn

  let openMediaUploader

  onMount(async () => {
    try {
      const res = await fetch("API_URL/prayer-calendar")
      if (!res.ok) throw await res.json()
      const calendars = await res.json()
      calendarFr = calendars.fr
      calendarEn = calendars.en
    } catch (error) {
      alert(error.message)
    }
  })

  async function update() {
    try {
      const res = await fetch("API_URL/prayer-calendar", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify({
          fr: calendarFr,
          frPrevious: calendarFrPrevious,
          en: calendarEn,
        }),
      })
      if (!res.ok) throw await res.json()
    } catch (error) {
      alert(error.message)
    }
  }

  function handleCalendarFr(mediaUrl) {
    calendarFrPrevious = calendarFr
    calendarFr = mediaUrl
    update()
  }

  function handleCalendarFrPrevious(mediaUrl) {
    calendarFrPrevious = mediaUrl
    update()
  }

  function handleCalendarEn(mediaUrl) {
    calendarEn = mediaUrl
    update()
  }

  function removeCalendarFr() {
    calendarFr = null
    update()
  }

  function removeCalendarFrPrevious() {
    calendarFrPrevious = null
    update()
  }

  function removeCalendarEn() {
    calendarEn = null
    update()
  }
</script>

<h1>Calendrier de prière</h1>

<div class="my flex">
  <div>
    <h2>Français</h2>

    <div class="my">
      <button
        on:click={openMediaUploader(handleCalendarFr, ["application/pdf"])}
      >
        Sélectionner le calendrier
      </button>
      <button class="danger" on:click={removeCalendarFr}>×</button>
    </div>

    {#if calendarFr}
      <iframe
        height="500"
        title="Calendrier de prière francophone"
        src={calendarFr}
      />
    {:else}
      Pas de calendrier en français
    {/if}

    <h2>Français (mois précédent)</h2>

    <div class="my">
      <button
        on:click={openMediaUploader(handleCalendarFrPrevious, [
          "application/pdf",
        ])}
      >
        Sélectionner le calendrier du mois précédent
      </button>
      <button class="danger" on:click={removeCalendarFrPrevious}>×</button>
    </div>

    {#if calendarFrPrevious}
      <iframe
        height="500"
        title="Calendrier de prière francophone (mois précédent)"
        src={calendarFrPrevious}
      />
    {:else}
      Pas de calendrier en français (mois précédent)
    {/if}
  </div>

  <div>
    <h2>English</h2>

    <div class="my">
      <button
        on:click={openMediaUploader(handleCalendarEn, ["application/pdf"])}
      >
        Select the calendar
      </button>
      <button class="danger" on:click={removeCalendarEn}>×</button>
    </div>

    {#if calendarEn}
      <iframe
        height="500"
        title="Calendrier de prière anglophone"
        src={calendarEn}
      />
    {:else}
      No English calendar
    {/if}
  </div>
</div>

<MediaUploader bind:open={openMediaUploader} />
