export const datetimeForInput = (datestr) => {
  const date = datestr ? new Date(datestr) : new Date()

  const year = date.getFullYear()
  const month = ("0" + (date.getMonth() + 1)).slice(-2)
  const day = ("0" + date.getDate()).slice(-2)
  const hour = ("0" + date.getHours()).slice(-2)
  const minute = ("0" + date.getMinutes()).slice(-2)

  return `${year}-${month}-${day}T${hour}:${minute}`
}

export const acceptedMimetypes = (imagesOnly = false) => {
  let mimetypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/tiff",
  ]

  if (!imagesOnly)
    mimetypes.push(
      "application/pdf",
      "audio/mpeg",
      "audio/aac",
      "video/mp4",
      "video/mpeg",
      "application/zip",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.oasis.opendocument.text"
    )

  return mimetypes
}
