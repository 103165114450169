<script>
  export let themes
  export let selectedThemeIds

  function changes(id) {
    return (e) => {
      if (e.target.checked) {
        selectedThemeIds = [...selectedThemeIds, id]
      } else {
        selectedThemeIds = selectedThemeIds.filter(
          (selectedThemeId) => selectedThemeId !== id
        )
      }
    }
  }
</script>

<ul class="basic">
  {#if themes}
    {#each themes as theme}
      <li>
        <label class="checkbox">
          <input
            type="checkbox"
            checked={selectedThemeIds.includes(theme.id)}
            on:change={changes(theme.id)}
          />
          {theme.name}
        </label>
        <svelte:self themes={theme.children} bind:selectedThemeIds />
      </li>
    {/each}
  {/if}
</ul>

<style>
  label {
    margin: 0;
  }
</style>
