<script>
  import { onMount } from "svelte"
  import Podcasts from "./Podcasts.svelte"
  import RichText from "./RichText.svelte"
  import { apiKey, page } from "./store.js"
  import ThemeListForm from "./ThemeListForm.svelte"
  import { datetimeForInput } from "./utils.js"

  const id = $page.data?.podcastId
  let publishedAt = datetimeForInput()
  let title
  let youtubeId
  let text
  let selectedThemeIds = []

  let themes = []

  onMount(async () => {
    try {
      let res = await fetch("API_URL/themes")
      if (!res.ok) throw await res.json()
      themes = await res.json()

      if (id) {
        res = await fetch("API_URL/podcasts/" + id, {
          headers: {
            Authorization: "Bearer " + $apiKey,
          },
        })
        if (!res.ok) throw await res.json()
        const podcast = await res.json()

        publishedAt = datetimeForInput(podcast.publishedAt)
        title = podcast.title
        youtubeId = podcast.youtubeId
        text = podcast.text
        selectedThemeIds = podcast.themes?.map((theme) => theme.id) || []
      }
    } catch (error) {
      alert(error.message)
    }
  })

  async function submit() {
    if (!title) return alert("Le titre est requis")
    if (!youtubeId) return alert("L'ID YouTube de le podcast est requis")

    try {
      const res = await fetch(`API_URL/podcasts${id ? "/" + id : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify({
          publishedAt: new Date(publishedAt),
          title,
          youtubeId,
          text,
          themeIds: selectedThemeIds,
        }),
      })
      if (!res.ok) throw await res.json()
      $page = { component: Podcasts }
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>
  {#if id}Modification d'un podcast{:else}Nouveau podcast{/if}
</h1>

<label>
  Titre<br />
  <input id="title" bind:value={title} />
</label>

<label>
  Publié le<br />
  <input id="publishedAt" type="datetime-local" bind:value={publishedAt} />
</label>

<label>
  YouTube ID<br />
  <input type="text" bind:value={youtubeId} />
</label>

<label for="text">
  Texte<br />
  <RichText bind:value={text} />
</label>

<label for="">
  Thèmes<br />
  <ThemeListForm bind:themes bind:selectedThemeIds />
</label>

<hr />

<button on:click={submit}>
  {id ? "✎ Modifier le podcast" : "+ Ajouter le podcast"}
</button>
