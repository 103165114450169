<script>
  import { onMount } from "svelte"
  import RichText from "./RichText.svelte"
  import { apiKey, page } from "./store.js"
  import ThemeListForm from "./ThemeListForm.svelte"
  import { datetimeForInput } from "./utils.js"
  import Videos from "./Videos.svelte"

  const id = $page.data?.videoId
  let publishedAt = datetimeForInput()
  let title
  let youtubeId
  let text
  let authorId
  let selectedThemeIds = []

  let authors = []
  let themes = []

  onMount(async () => {
    try {
      let res = await fetch("API_URL/authors")
      if (!res.ok) throw await res.json()
      authors = await res.json()

      res = await fetch("API_URL/themes")
      if (!res.ok) throw await res.json()
      themes = await res.json()

      if (id) {
        res = await fetch("API_URL/videos/" + id, {
          headers: {
            Authorization: "Bearer " + $apiKey,
          },
        })
        if (!res.ok) throw await res.json()
        const video = await res.json()

        publishedAt = datetimeForInput(video.publishedAt)
        title = video.title
        youtubeId = video.youtubeId
        text = video.text
        authorId = video.author?.id
        selectedThemeIds = video.themes?.map((theme) => theme.id) || []
      }
    } catch (error) {
      alert(error.message)
    }
  })

  async function submit() {
    if (!title) return alert("Le titre est requis")
    if (!youtubeId) return alert("L'ID YouTube de la vidéo est requis")

    try {
      const res = await fetch(`API_URL/videos${id ? "/" + id : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify({
          publishedAt: new Date(publishedAt),
          title,
          youtubeId,
          text,
          authorId,
          themeIds: selectedThemeIds,
        }),
      })
      if (!res.ok) throw await res.json()
      $page = { component: Videos }
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>
  {#if id}Modification d'une vidéo{:else}Nouvelle vidéo{/if}
</h1>

<label>
  Titre<br />
  <input id="title" bind:value={title} />
</label>

<label>
  Publiée le<br />
  <input id="publishedAt" type="datetime-local" bind:value={publishedAt} />
</label>

<label>
  Auteur<br />
  <select bind:value={authorId}>
    <option value={null} />
    {#each authors as author}
      <option value={author.id}>
        {author.name}
      </option>
    {/each}
  </select>
</label>

<label>
  YouTube ID<br />
  <input type="text" bind:value={youtubeId} />
</label>

<label for="text">
  Texte<br />
  <RichText bind:value={text} />
</label>

<label for="">
  Thèmes<br />
  <ThemeListForm bind:themes bind:selectedThemeIds />
</label>

<hr />

<button on:click={submit}>
  {id ? "✎ Modifier la vidéo" : "+ Ajouter la vidéo"}
</button>
