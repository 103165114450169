<script>
  import { onMount } from "svelte"
  import { apiKey, page } from "./store.js"
  import VideoForm from "./VideoForm.svelte"

  let videos = []

  onMount(async () => {
    try {
      const res = await fetch("API_URL/videos", {
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      videos = await res.json()
    } catch (error) {
      alert(error.message)
    }
  })

  async function destroy(video) {
    if (!confirm(`Voulez-vous vraiment supprimer la vidéo "${video.title}" ?`))
      return

    try {
      const res = await fetch("API_URL/videos/" + video.id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      videos = videos.filter((el) => el.id !== video.id)
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>Vidéos</h1>

<div class="my">
  <button on:click={() => ($page = { component: VideoForm })}>
    + Ajouter une vidéo
  </button>
</div>

<table class="my">
  <thead>
    <tr>
      <th>Titre</th>
      <th>YouTube ID</th>
      <th>Publiée le</th>
      <th />
    </tr>
  </thead>
  <tbody>
    {#each videos as video}
      <tr>
        <td>{video.title}</td>
        <td>{video.youtubeId}</td>
        <td class:pending={new Date(video.publishedAt) > new Date()}>
          {new Date(video.publishedAt).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </td>
        <td>
          <button
            class="secondary"
            on:click={() =>
              ($page = { component: VideoForm, data: { videoId: video.id } })}
          >
            ✎ Modifier
          </button>
          <button class="danger" on:click={() => destroy(video)}>×</button>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
