<script>
  import Sortable from "sortablejs"
  import { onMount } from "svelte"
  import { apiKey } from "./store.js"

  export let themes
  export let parentId = null

  var themesList

  let updateDelay
  function update(themeId, name) {
    clearTimeout(updateDelay)
    updateDelay = setTimeout(async () => {
      try {
        const res = await fetch("API_URL/themes/" + themeId, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + $apiKey,
          },
          body: JSON.stringify({ name }),
        })
        if (!res.ok) throw await res.json()
      } catch (error) {
        alert(error.message)
      }
    }, 1000)
  }

  async function remove(themeId, element) {
    try {
      if (element.querySelector("ul").childElementCount)
        return alert(
          "Un thème ayant encore des sous-thèmes ne peut être supprimé.\nVeuillez déplacer les sous-thèmes à une autre position avant de continuer."
        )

      if (!confirm("Voulez-vous vraiment supprimer ce thème ?")) return

      const res = await fetch("API_URL/themes/" + themeId, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      element.remove()
    } catch (error) {
      alert(error.message)
    }
  }

  onMount(async () => {
    new Sortable(themesList, {
      group: "nested",
      fallbackOnBody: true,
      invertSwap: true,
      onEnd: async (evt) => {
        try {
          // Step 1: set theme's new parent
          let res = await fetch("API_URL/themes/" + evt.item.dataset.id, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + $apiKey,
            },
            body: JSON.stringify({
              parentId: evt.to.dataset.id || null,
            }),
          })
          if (!res.ok) throw await res.json()

          // Step 2: set order for each theme of parent
          for (let i = 0; i < evt.to.children.length; i++) {
            res = await fetch(
              "API_URL/themes/" + evt.to.children[i].dataset.id,
              {
                method: "PATCH",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + $apiKey,
                },
                body: JSON.stringify({
                  order: i,
                }),
              }
            )
            if (!res.ok) throw await res.json()
          }
        } catch (error) {
          alert(error.message)
        }
      },
    })
  })
</script>

<ul bind:this={themesList} data-id={parentId} class="basic sortable">
  {#if themes}
    {#each themes as theme}
      <li data-id={theme.id}>
        <input
          value={theme.name}
          on:input={(e) => update(theme.id, e.target.value)}
        />
        <button
          class="danger"
          on:click={(e) => remove(theme.id, e.target.parentElement)}
        >
          ×
        </button>
        <svelte:self parentId={theme.id} themes={theme.children} />
      </li>
    {/each}
  {/if}
</ul>

<style>
  li {
    cursor: move;
  }
</style>
