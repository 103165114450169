<script>
  import { onMount } from "svelte"
  import MediaUploader from "./MediaUploader.svelte"
  import Recensions from "./Recensions.svelte"
  import RichText from "./RichText.svelte"
  import { apiKey, page } from "./store.js"
  import ThemeListForm from "./ThemeListForm.svelte"
  import { acceptedMimetypes, datetimeForInput } from "./utils.js"

  const id = $page.data?.recensionId
  let publishedAt = datetimeForInput()
  let title
  let cover
  let bookCover
  let bookAuthor
  let bookPublisher
  let releasedOn
  let text
  let authorId
  let selectedThemeIds = []

  let openMediaUploader

  let authors = []
  let themes = []

  onMount(async () => {
    try {
      let res = await fetch("API_URL/authors")
      if (!res.ok) throw await res.json()
      authors = await res.json()

      res = await fetch("API_URL/themes")
      if (!res.ok) throw await res.json()
      themes = await res.json()

      if (id) {
        res = await fetch("API_URL/recensions/" + id, {
          headers: {
            Authorization: "Bearer " + $apiKey,
          },
        })
        if (!res.ok) throw await res.json()
        const recension = await res.json()

        publishedAt = datetimeForInput(recension.publishedAt)
        title = recension.title
        cover = recension.cover
        bookCover = recension.bookCover
        bookAuthor = recension.bookAuthor
        bookPublisher = recension.bookPublisher
        releasedOn = recension.releasedOn
        text = recension.text
        authorId = recension.author?.id
        selectedThemeIds = recension.themes?.map((theme) => theme.id) || []
      }
    } catch (error) {
      alert(error.message)
    }
  })

  function handleCoverMedia(mediaUrl) {
    cover = mediaUrl
  }

  function handleBookCoverMedia(mediaUrl) {
    bookCover = mediaUrl
  }

  async function submit() {
    if (!title) return alert("Le titre est requis")
    try {
      const res = await fetch(`API_URL/recensions${id ? "/" + id : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify({
          publishedAt: new Date(publishedAt),
          title,
          cover,
          bookCover,
          bookAuthor,
          bookPublisher,
          releasedOn: releasedOn ? new Date(releasedOn) : null,
          text,
          authorId,
          themeIds: selectedThemeIds,
        }),
      })
      if (!res.ok) throw await res.json()
      $page = { component: Recensions }
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>
  {#if id}Modification d'une recension{:else}Nouvelle recension{/if}
</h1>

<label>
  Titre<br />
  <input bind:value={title} />
</label>

<label>
  Publiée le<br />
  <input type="datetime-local" bind:value={publishedAt} />
</label>

<label>
  Auteur<br />
  <select bind:value={authorId}>
    <option value="" />
    {#each authors as author}
      <option value={author.id}>
        {author.name}
      </option>
    {/each}
  </select>
</label>

<label>
  Couverture<br />
  {#if cover}
    <img src={cover} loading="lazy" height="200" alt="Couverture" />
  {/if}
  <button
    on:click={openMediaUploader(handleCoverMedia, acceptedMimetypes(true))}
  >
    Sélectionner
  </button>
</label>

<label>
  Couverture du livre<br />
  {#if bookCover}
    <img
      src={bookCover}
      loading="lazy"
      height="200"
      alt="Couverture du livre"
    />
  {/if}
  <button
    on:click={openMediaUploader(handleBookCoverMedia, acceptedMimetypes(true))}
  >
    Sélectionner
  </button>
</label>

<label>
  Auteur du livre<br />
  <input bind:value={bookAuthor} />
</label>

<label>
  Éditeur<br />
  <input bind:value={bookPublisher} />
</label>

<label>
  Livre sorti le<br />
  <input type="date" bind:value={releasedOn} />
</label>

<label for="text">
  Texte<br />
  <RichText bind:value={text} />
</label>

<label for="">
  Thèmes<br />
  <ThemeListForm bind:themes bind:selectedThemeIds />
</label>

<hr />

<button on:click={submit}>
  {id ? "✎ Modifier la recension" : "+ Ajouter la recension"}
</button>

<MediaUploader bind:open={openMediaUploader} />
