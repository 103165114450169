<script>
  import { onMount } from "svelte"
  import MediaUploader from "./MediaUploader.svelte"
  import Posts from "./Posts.svelte"
  import RichText from "./RichText.svelte"
  import { apiKey, page } from "./store.js"
  import ThemeListForm from "./ThemeListForm.svelte"
  import { acceptedMimetypes, datetimeForInput } from "./utils.js"

  const id = $page.data?.postId
  let publishedAt = datetimeForInput()
  let title
  let cover
  let text
  let authorId
  let selectedThemeIds = []

  let openMediaUploader

  let authors = []
  let themes = []

  onMount(async () => {
    try {
      let res = await fetch("API_URL/authors")
      if (!res.ok) throw await res.json()
      authors = await res.json()

      res = await fetch("API_URL/themes")
      if (!res.ok) throw await res.json()
      themes = await res.json()

      if (id) {
        res = await fetch("API_URL/posts/" + id, {
          headers: {
            Authorization: "Bearer " + $apiKey,
          },
        })
        if (!res.ok) throw await res.json()
        const post = await res.json()

        publishedAt = datetimeForInput(post.publishedAt)
        title = post.title
        cover = post.cover
        text = post.text
        authorId = post.author?.id
        selectedThemeIds = post.themes?.map((theme) => theme.id) || []
      }
    } catch (error) {
      alert(error.message)
    }
  })

  function handleCoverMedia(mediaUrl) {
    cover = mediaUrl
  }

  async function submit() {
    if (!title) return alert("Le titre est requis")
    try {
      const res = await fetch(`API_URL/posts${id ? "/" + id : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify({
          publishedAt: new Date(publishedAt),
          title,
          cover,
          text,
          authorId,
          themeIds: selectedThemeIds,
        }),
      })
      if (!res.ok) throw await res.json()
      $page = { component: Posts }
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>
  {#if id}Modification d'un article{:else}Nouvel article{/if}
</h1>

<label>
  Titre<br />
  <input bind:value={title} />
</label>

<label>
  Publié le<br />
  <input type="datetime-local" bind:value={publishedAt} />
</label>

<label>
  Auteur<br />
  <select bind:value={authorId}>
    <option value="" />
    {#each authors as author}
      <option value={author.id}>
        {author.name}
      </option>
    {/each}
  </select>
</label>

<label>
  Couverture<br />
  {#if cover}
    <img src={cover} loading="lazy" height="200" alt="Couverture" />
  {/if}
  <button
    on:click={openMediaUploader(handleCoverMedia, acceptedMimetypes(true))}
  >
    Sélectionner
  </button>
</label>

<label for="text">
  Texte<br />
  <RichText bind:value={text} />
</label>

<label for="">
  Thèmes<br />
  <ThemeListForm bind:themes bind:selectedThemeIds />
</label>

<hr />

<button on:click={submit}>
  {id ? "✎ Modifier l'article" : "+ Ajouter l'article"}
</button>

<MediaUploader bind:open={openMediaUploader} />
