<script>
  import { onMount } from "svelte"
  import PostForm from "./PostForm.svelte"
  import { apiKey, page } from "./store.js"

  let posts = []

  onMount(async () => {
    try {
      const res = await fetch("API_URL/posts", {
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      posts = await res.json()
    } catch (error) {
      alert(error.message)
    }
  })

  async function destroy(post) {
    if (!confirm(`Voulez-vous vraiment supprimer l'article "${post.title}" ?`))
      return

    try {
      const res = await fetch("API_URL/posts/" + post.id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      posts = posts.filter((el) => el.id !== post.id)
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>Articles</h1>

<div class="my">
  <button on:click={() => ($page = { component: PostForm })}>
    + Ajouter un article
  </button>
</div>

<table class="my">
  <thead>
    <tr>
      <th>Titre</th>
      <th>Publié le</th>
      <th />
    </tr>
  </thead>
  <tbody>
    {#each posts as post}
      <tr>
        <td>{post.title}</td>
        <td class:pending={new Date(post.publishedAt) > new Date()}>
          {new Date(post.publishedAt).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </td>
        <td>
          <button
            class="secondary"
            on:click={() =>
              ($page = { component: PostForm, data: { postId: post.id } })}
          >
            ✎ Modifier
          </button>
          <button class="danger" on:click={() => destroy(post)}>×</button>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
