<script>
  import "github-markdown-css"
  import MarkdownIt from "markdown-it"
  import MarkdownItFootnote from "markdown-it-footnote"
  import { createEventDispatcher } from "svelte"
  import MediaUploader from "./MediaUploader.svelte"
  import { acceptedMimetypes } from "./utils"

  const dispatch = createEventDispatcher()

  const md = new MarkdownIt({
    html: true,
    breaks: true,
    typographer: true,
    linkify: true,
  }).use(MarkdownItFootnote)

  export let value = ""
  let textarea
  let openMediaUploader

  function input() {
    dispatch("input", { value })
  }

  function bold() {
    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    value = [
      value.slice(0, start),
      "**",
      value.slice(start, end),
      "**",
      value.slice(end),
    ].join("")
  }

  function italic() {
    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    value = [
      value.slice(0, start),
      "*",
      value.slice(start, end),
      "*",
      value.slice(end),
    ].join("")
  }

  function quote() {
    const end = textarea.selectionEnd
    value = [value.slice(0, end), "\n> \n", value.slice(end)].join("")
  }

  function link() {
    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    value = [
      value.slice(0, start),
      "[",
      value.slice(start, end),
      "]()",
      value.slice(end),
    ].join("")
  }

  function handleMedia(mediaUrl) {
    const code = `![](${mediaUrl})`
    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    value = [value.slice(0, start), code, value.slice(end)].join("")
  }
</script>

<div class="flex">
  <div>
    <div class="format">
      <button on:click={bold} style="font-weight: bold;">B</button>
      <button on:click={italic} style="font-style: italic;">I</button>
      <button on:click={quote}>« »</button>
      <button on:click={link}>🔗</button>
      <button on:click={openMediaUploader(handleMedia, acceptedMimetypes())}>
        📂
      </button>
    </div>
    <textarea
      id="text"
      rows="20"
      bind:this={textarea}
      bind:value
      on:input={input}
    />
  </div>
  <div class="preview">
    <div class="markdown-body">
      {#if value}{@html md.render(value)}{/if}
    </div>
  </div>
</div>

<MediaUploader bind:open={openMediaUploader} />

<style>
  .format {
    margin-bottom: 5px;
  }

  .format button {
    text-align: center;
    min-width: 40px;
    background: #f1f3f5;
    border-color: #f1f3f5;
    color: #343a40;
  }

  textarea {
    font-family: monospace;
    font-size: 1.1rem;
  }

  .preview {
    background: #f8f9fa;
    padding: 25px;
    border-radius: 10px;
  }
</style>
