<script>
  import { onMount } from "svelte"
  import Sortable from "svelte-sortablejs"
  import MediaUploader from "./MediaUploader.svelte"
  import { apiKey } from "./store.js"
  import { acceptedMimetypes } from "./utils"

  let team = []

  let newCategoryName, newMemberName, newTitle, newPicture

  let openMediaUploader

  onMount(async () => {
    try {
      const res = await fetch("API_URL/team")
      if (!res.ok) throw await res.json()
      team = await res.json()
    } catch (error) {
      alert(error.message)
    }
  })

  function addCategory() {
    if (!newCategoryName) return alert("Le nom de la catégorie est requise")

    team = [...team, { name: newCategoryName, members: [] }]

    update()
    newCategoryName = null
  }

  function addMember() {
    if (!newMemberName) return alert("Le nom de la personne est requis")
    if (!newTitle) return alert("Le titre de la personne est requis")
    if (!newPicture) return alert("La photo la personne est requise")
    if (!team.length)
      return alert("L'équipe doit contenir au moins une catégorie")

    team[team.length - 1].members.push({
      name: newMemberName,
      title: newTitle,
      picture: newPicture,
    })
    team = [...team]

    update()
    newMemberName = null
    newTitle = null
    newPicture = null
  }

  function handleNewPicture(mediaUrl) {
    newPicture = mediaUrl
  }

  async function update() {
    try {
      const res = await fetch("API_URL/team", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify(team),
      })
      if (!res.ok) throw await res.json()
    } catch (error) {
      alert(error.message)
    }
  }

  async function removeCategory(name) {
    team = team.filter((category) => category.name !== name)
    await update()
  }

  async function removeMember(name) {
    team = team.map((category) => {
      category.members = category.members.filter(
        (member) => member.name !== name
      )
      return category
    })
    await update()
  }
</script>

<h1>Équipe</h1>

<h2>Nouvelle catégorie</h2>
<div>
  <input bind:value={newCategoryName} placeholder="Nom de la catégorie" />
  <button on:click={addCategory}>+ Ajouter la catégorie</button>
</div>

<h2>Nouveau membre</h2>
<div>
  {#if newPicture}<img src={newPicture} alt="Média" height="150" />{/if}
  <button
    on:click={openMediaUploader(handleNewPicture, acceptedMimetypes(true))}
  >
    Sélectionner la photo
  </button>
  <input bind:value={newMemberName} placeholder="Nom" />
  <input bind:value={newTitle} placeholder="Titre" />
  <button on:click={addMember}>+ Ajouter le membre</button>
</div>

<hr />

<ul class="basic sortable">
  <Sortable bind:list={team} options={{ group: "categories", onEnd: update }}>
    {#each team as category (category.name)}
      <li>
        <h2>{category.name}</h2>
        {#if !category.members.length}
          <button on:click={removeCategory(category.name)}>Supprimer</button>
        {/if}
        <ul class="basic sortable">
          <Sortable
            bind:list={category.members}
            options={{ group: "members", onEnd: update }}
          >
            {#each category.members as member (member.name)}
              <li>
                {#if member.picture}
                  <img src={member.picture} alt="Profil" height="100" />
                {/if}
                {member.name}<span class="title">, {member.title}</span>
                <button class="danger" on:click={removeMember(member.name)}>
                  ×
                </button>
              </li>
            {/each}
          </Sortable>
        </ul>
      </li>
    {/each}
  </Sortable>
</ul>

<MediaUploader bind:open={openMediaUploader} />

<style>
  ul h2 {
    margin-top: 35px;
  }

  .title {
    color: #868e96;
  }
</style>
