<script>
  import { onMount } from "svelte"
  import Sortable from "svelte-sortablejs"
  import MediaUploader from "./MediaUploader.svelte"
  import { apiKey } from "./store.js"
  import { acceptedMimetypes } from "./utils"

  let testimonies = []

  let newName, newTitle, newPicture, newText

  let openMediaUploader

  onMount(async () => {
    try {
      const res = await fetch("API_URL/testimonies")
      if (!res.ok) throw await res.json()
      testimonies = await res.json()
    } catch (error) {
      alert(error.message)
    }
  })

  function add() {
    if (!newName) return alert("Le nom de la personne est requis")
    if (!newTitle) return alert("Le titre de la personne est requis")
    if (!newPicture) return alert("La photo la personne est requise")
    if (!newText) return alert("Le contenu de témoignage est requis")

    testimonies = [
      ...testimonies,
      { name: newName, title: newTitle, picture: newPicture, text: newText },
    ]

    update()
    newName = null
    newTitle = null
    newPicture = null
    newText = null
  }

  async function update() {
    try {
      const res = await fetch("API_URL/testimonies", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify(testimonies),
      })
      if (!res.ok) throw await res.json()
    } catch (error) {
      alert(error.message)
    }
  }

  async function remove(item) {
    testimonies = testimonies.filter((t) => t.name != item.name)
    await update()
  }

  function handleNewPicture(mediaUrl) {
    newPicture = mediaUrl
  }
</script>

<h1>Témoignages</h1>

<h2>Nouveau témoignage</h2>

{#if newPicture}<img src={newPicture} alt="Média" height="150" />{/if}
<button on:click={openMediaUploader(handleNewPicture, acceptedMimetypes(true))}>
  Sélectionner la photo
</button>
<input bind:value={newName} placeholder="Nom" />
<input bind:value={newTitle} placeholder="Titre" />

<label for="text">
  <textarea cols="30" rows="10" bind:value={newText} placeholder="Témoignage" />
</label>

<button on:click={add}>+ Ajouter le témoignage</button>

<hr />

<ul class="basic sortable">
  <Sortable bind:list={testimonies} options={{ onEnd: update }}>
    {#each testimonies as item}
      <li data-name={item.name}>
        <img src={item.picture} alt="Média" height="150" />
        {item.name}
        {item.title}
        {item.text}
        <button class="danger" on:click={remove(item)}>×</button>
      </li>
    {/each}
  </Sortable>
</ul>

<MediaUploader bind:open={openMediaUploader} />
