<script>
  import { onMount } from "svelte"
  import Maillons from "./Maillons.svelte"
  import MediaUploader from "./MediaUploader.svelte"
  import RichText from "./RichText.svelte"
  import { apiKey, page } from "./store.js"
  import ThemeListForm from "./ThemeListForm.svelte"
  import { acceptedMimetypes, datetimeForInput } from "./utils.js"

  const id = $page.data?.maillonId
  let publishedAt = datetimeForInput()
  let title
  let cover
  let pdf
  let text
  let selectedThemeIds = []

  let openMediaUploader

  let themes = []

  onMount(async () => {
    try {
      let res = await fetch("API_URL/themes")
      if (!res.ok) throw await res.json()
      themes = await res.json()

      if (id) {
        res = await fetch("API_URL/maillons/" + id, {
          headers: {
            Authorization: "Bearer " + $apiKey,
          },
        })
        if (!res.ok) throw await res.json()
        const maillon = await res.json()

        publishedAt = datetimeForInput(maillon.publishedAt)
        title = maillon.title
        cover = maillon.cover
        pdf = maillon.pdf
        text = maillon.text
        selectedThemeIds = maillon.themes?.map((theme) => theme.id) || []
      }
    } catch (error) {
      alert(error.message)
    }
  })

  function handleCoverMedia(mediaUrl) {
    cover = mediaUrl
  }

  function handlePdfMedia(mediaUrl) {
    pdf = mediaUrl
  }

  async function submit() {
    if (!title) return
    try {
      const res = await fetch(`API_URL/maillons${id ? "/" + id : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify({
          publishedAt: new Date(publishedAt),
          title,
          cover,
          pdf,
          text,
          themeIds: selectedThemeIds,
        }),
      })
      if (!res.ok) throw await res.json()
      $page = { component: Maillons }
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>
  {#if id}Modification d'un Maillon{:else}Nouveau Maillon{/if}
</h1>

<label>
  Titre<br />
  <input bind:value={title} />
</label>

<label>
  Publié le<br />
  <input type="datetime-local" bind:value={publishedAt} />
</label>

<label>
  Couverture<br />
  {#if cover}
    <img src={cover} loading="lazy" height="200" alt="Couverture" />
  {/if}
  <button
    on:click={openMediaUploader(handleCoverMedia, acceptedMimetypes(true))}
  >
    Sélectionner
  </button>
</label>

<label>
  PDF<br />
  {#if pdf}
    <iframe height="200" title="Calendrier de prière anglophone" src={pdf} />
  {/if}
  <button on:click={openMediaUploader(handlePdfMedia, ["application/pdf"])}>
    Sélectionner
  </button>
</label>

<label for="text">
  Texte<br />
  <RichText bind:value={text} />
</label>

<label for="">
  Thèmes<br />
  <ThemeListForm bind:themes bind:selectedThemeIds />
</label>

<div>
  <button on:click={submit}>
    {id ? "✎ Modifier le Maillon" : "+ Ajouter le Maillon"}
  </button>
</div>

<MediaUploader bind:open={openMediaUploader} />

<style>
  iframe {
    width: 200px;
    display: inline;
    vertical-align: middle;
  }
</style>
