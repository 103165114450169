<script>
  import { onMount } from "svelte"
  import { apiKey } from "./store.js"
  import ThemeListSortable from "./ThemeListSortable.svelte"

  let themes = []
  let newThemeName

  onMount(async () => {
    try {
      const res = await fetch("API_URL/themes")
      if (!res.ok) throw await res.json()
      themes = await res.json()
    } catch (error) {
      alert(error.message)
    }
  })

  async function add() {
    try {
      const res = await fetch("API_URL/themes", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify({ name: newThemeName }),
      })
      if (!res.ok) throw await res.json()

      const theme = await res.json()
      themes = [...themes, theme]
      newThemeName = null
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>Thèmes</h1>

<div class="my">
  <input
    bind:value={newThemeName}
    on:change={add}
    placeholder="Nouveau thème"
  />
</div>

<ThemeListSortable bind:themes />
