<script>
  import { onMount } from "svelte"
  import RichText from "./RichText.svelte"
  import { apiKey } from "./store.js"

  let text

  onMount(async () => {
    try {
      let res = await fetch("API_URL/open-doors")
      if (!res.ok) throw await res.json()
      text = await res.text()
    } catch (error) {
      alert(error.message)
    }
  })

  let updateDelay
  async function update() {
    clearTimeout(updateDelay)
    updateDelay = setTimeout(async () => {
      try {
        const res = await fetch("API_URL/open-doors", {
          method: "POST",
          headers: {
            Authorization: "Bearer " + $apiKey,
          },
          body: text,
        })
        if (!res.ok) throw await res.json()
      } catch (error) {
        alert(error.message)
      }
    }, 1000)
  }
</script>

<h1>Portes ouvertes</h1>

<div>
  <RichText bind:value={text} on:input={update} />
</div>
