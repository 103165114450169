<script>
  import { onMount } from "svelte"
  import MediaUploader from "./MediaUploader.svelte"
  import SeminaryForm from "./SeminaryForm.svelte"
  import { apiKey, page } from "./store.js"
  import { acceptedMimetypes } from "./utils"

  let seminaries = []
  let cover

  let openMediaUploader

  onMount(async () => {
    try {
      let res = await fetch("API_URL/seminaries?sort=desc")
      if (!res.ok) throw await res.json()
      seminaries = await res.json()

      res = await fetch("API_URL/seminaries/cover")
      if (!res.ok) throw await res.json()
      cover = await res.text()
    } catch (error) {
      alert(error.message)
    }
  })

  async function destroy(seminary) {
    if (
      !confirm(
        `Voulez-vous vraiment supprimer le séminaire "${seminary.title}" ?`
      )
    )
      return

    try {
      const res = await fetch("API_URL/seminaries/" + seminary.id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      seminaries = seminaries.filter((el) => el.id !== seminary.id)
    } catch (error) {
      alert(error.message)
    }
  }

  function handleCoverMedia(mediaUrl) {
    cover = mediaUrl
    coverUpdate()
  }

  async function coverUpdate() {
    try {
      const res = await fetch("API_URL/seminaries/cover", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
        body: cover,
      })
      if (!res.ok) throw await res.json()
    } catch (error) {
      alert(error.message)
    }
  }

  async function coverDestroy() {
    try {
      const res = await fetch("API_URL/seminaries/cover", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      cover = null
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>Séminaires</h1>

<h2>Couverture</h2>

<div class="my">
  {#if cover}
    <img src={cover} loading="lazy" width="200" alt="Couverture" />
  {/if}
  <button
    on:click={openMediaUploader(handleCoverMedia, acceptedMimetypes(true))}
  >
    Sélectionner
  </button>

  <button class="danger" on:click={coverDestroy}>×</button>
</div>

<hr />

<h2>Liste des séminaires</h2>

<div class="my">
  <button on:click={() => ($page = { component: SeminaryForm })}>
    + Ajouter un séminaire
  </button>
</div>

<table class="my">
  <thead>
    <tr>
      <th>Titre</th>
      <th>Sous-titre</th>
      <th>Expire le</th>
      <th />
    </tr>
  </thead>
  <tbody>
    {#each seminaries as seminary}
      <tr>
        <td>{seminary.title}</td>
        <td>
          {#if seminary.subtitle}
            {seminary.subtitle}
          {:else}
            —
          {/if}
        </td>
        <td class:pending={new Date(seminary.expiresAt) > new Date()}>
          {#if seminary.expiresAt}
            {new Date(seminary.expiresAt).toLocaleDateString("fr", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          {:else}
            —
          {/if}
        </td>
        <td>
          <button
            class="secondary"
            on:click={() =>
              ($page = {
                component: SeminaryForm,
                data: { seminaryId: seminary.id },
              })}
          >
            ✎ Modifier
          </button>
          <button class="danger" on:click={() => destroy(seminary)}>×</button>
        </td>
      </tr>
    {/each}
  </tbody>
</table>

<MediaUploader bind:open={openMediaUploader} />
