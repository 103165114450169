<script>
  import { onMount } from "svelte"
  import { apiKey } from "./store.js"

  let authors = []
  let newName

  onMount(async () => {
    try {
      const res = await fetch("API_URL/authors")
      if (!res.ok) throw await res.json()
      authors = await res.json()
    } catch (error) {
      alert(error.message)
    }
  })

  function checkExists(name) {
    return authors.find((author) => author.name === name) ? true : false
  }

  async function add() {
    if (checkExists(newName)) return alert("Cet auteur existe déjà")
    try {
      const res = await fetch("API_URL/authors", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify({ name: newName }),
      })
      if (!res.ok) throw await res.json()
      const author = await res.json()
      authors = [...authors, author].sort((a, b) => a.name > b.name)
      newName = null
    } catch (error) {
      alert(error.message)
    }
  }

  let updateDelay
  function update(authorId, name) {
    clearTimeout(updateDelay)
    updateDelay = setTimeout(async () => {
      if (checkExists(name)) return alert("Cet auteur existe déjà")
      try {
        const res = await fetch("API_URL/authors/" + authorId, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + $apiKey,
          },
          body: JSON.stringify({ name }),
        })
        if (!res.ok) throw await res.json()

        authors = authors
          .map((author) => {
            if (author.id === authorId) author.name = name
            return author
          })
          .sort((a, b) => a.name > b.name)
      } catch (error) {
        alert(error.message)
      }
    }, 1000)
  }

  async function remove(author) {
    if (!confirm(`Voulez-vous vraiment supprimer ${author.name} ?`)) return

    try {
      const res = await fetch("API_URL/authors/" + author.id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      authors = authors.filter((el) => el.id !== author.id)
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>Auteurs</h1>

<div class="my">
  <input bind:value={newName} on:change={add} placeholder="Nouvel auteur" />
</div>

<ul class="my basic">
  {#each authors as author}
    <li>
      <input
        value={author.name}
        on:input={(e) => update(author.id, e.target.value)}
      />
      <button class="danger" on:click={() => remove(author)}>×</button>
    </li>
  {/each}
</ul>
