<script>
  import { onMount } from "svelte"
  import MaillonForm from "./MaillonForm.svelte"
  import { apiKey, page } from "./store.js"

  let maillons = []

  onMount(async () => {
    try {
      const res = await fetch("API_URL/maillons", {
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      maillons = await res.json()
    } catch (error) {
      alert(error.message)
    }
  })

  async function destroy(maillon) {
    if (
      !confirm(`Voulez-vous vraiment supprimer l'article "${maillon.title}" ?`)
    )
      return

    try {
      const res = await fetch("API_URL/maillons/" + maillon.id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      maillons = maillons.filter((el) => el.id !== maillon.id)
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>Maillons</h1>

<div class="my">
  <button on:click={() => ($page = { component: MaillonForm })}>
    + Ajouter un Maillon
  </button>
</div>

<table class="my">
  <thead>
    <tr>
      <th>Titre</th>
      <th>Publié le</th>
      <th />
    </tr>
  </thead>
  <tbody>
    {#each maillons as maillon}
      <tr>
        <td>{maillon.title}</td>
        <td class:pending={new Date(maillon.publishedAt) > new Date()}>
          {new Date(maillon.publishedAt).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </td>
        <td>
          <button
            class="secondary"
            on:click={() =>
              ($page = {
                component: MaillonForm,
                data: { maillonId: maillon.id },
              })}
          >
            ✎ Modifier
          </button>
          <button class="danger" on:click={() => destroy(maillon)}>×</button>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
