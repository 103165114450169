<script>
  import { onMount } from "svelte"
  import Authors from "./Authors.svelte"
  import EvangelismWeek from "./EvangelismWeek.svelte"
  import HomeNews from "./HomeNews.svelte"
  import Maillons from "./Maillons.svelte"
  import OpenDoors from "./OpenDoors.svelte"
  import Podcasts from "./Podcasts.svelte"
  import Posts from "./Posts.svelte"
  import PrayerCalendar from "./PrayerCalendar.svelte"
  import Recensions from "./Recensions.svelte"
  import SaturdayLessons from "./SaturdayLessons.svelte"
  import Schedule from "./Schedule.svelte"
  import Seminaries from "./Seminaries.svelte"
  import { apiKey, page } from "./store.js"
  import Team from "./Team.svelte"
  import Testimonies from "./Testimonies.svelte"
  import Themes from "./Themes.svelte"
  import Videos from "./Videos.svelte"

  onMount(async () => {
    try {
      const res = await fetch("API_URL/sign", {
        method: "POST",
        body: $apiKey,
      })

      if (!res.ok) {
        localStorage.removeItem("apiKey")
        $apiKey = null
      }
    } catch (error) {
      alert(error.message)
    }
  })

  let apiKeyInput
  async function signIn() {
    try {
      const res = await fetch("API_URL/sign", {
        method: "POST",
        body: apiKeyInput,
      })
      if (!res.ok) return alert("Clé incorrecte")

      localStorage.setItem("apiKey", apiKeyInput)
      $apiKey = apiKeyInput
    } catch (error) {
      alert(error.message)
    }
  }
</script>

{#if $apiKey}
  <div class="global">
    <header>
      <h1>IBB Admin</h1>
      <nav>
        <div on:click={() => ($page = { component: Posts })}>Articles</div>
        <div on:click={() => ($page = { component: Recensions })}>
          Recensions
        </div>
        <div on:click={() => ($page = { component: Videos })}>Vidéos</div>
        <div on:click={() => ($page = { component: Podcasts })}>Podcasts</div>
        <div on:click={() => ($page = { component: Maillons })}>Maillons</div>
        <hr />
        <div on:click={() => ($page = { component: Authors })}>Auteurs</div>
        <div on:click={() => ($page = { component: Themes })}>Thèmes</div>
        <hr />
        <div on:click={() => ($page = { component: HomeNews })}>À la une</div>
        <div on:click={() => ($page = { component: Seminaries })}>
          Séminaires
        </div>
        <div on:click={() => ($page = { component: SaturdayLessons })}>
          Cours du samedi
        </div>
        <div on:click={() => ($page = { component: PrayerCalendar })}>
          Calendrier de prière
        </div>
        <div on:click={() => ($page = { component: Schedule })}>Horaires</div>
        <div on:click={() => ($page = { component: OpenDoors })}>
          Portes ouvertes
        </div>
        <div on:click={() => ($page = { component: EvangelismWeek })}>
          Semaine d'évangélisation
        </div>
        <div on:click={() => ($page = { component: Team })}>Équipe</div>
        <div on:click={() => ($page = { component: Testimonies })}>
          Témoignages
        </div>
      </nav>
    </header>

    <main>
      <svelte:component this={$page.component} />
    </main>
  </div>
{:else}
  <div class="login">
    <div>
      <h1>IBB Admin</h1>
      <input
        type="password"
        bind:value={apiKeyInput}
        placeholder="Clé d'accès"
      />
      <button on:click={signIn}>Connexion</button>
    </div>
  </div>
{/if}

<style>
  .global {
    display: flex;
    min-height: 100vh;
  }

  header {
    background: #000;
    padding: 30px;
    color: #ced4da;
  }

  h1 {
    font-weight: 400;
    color: #212529;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  header nav div {
    cursor: pointer;
    font-size: 1.1em;
    padding: 5px 0;
  }

  header nav div:hover {
    color: #1fcdc9;
  }

  header nav hr {
    border-color: #212529;
    margin: 10px 0;
  }

  main {
    flex-grow: 1;
    padding: 30px;
  }

  .login {
    padding: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  .login h1 {
    font-weight: 200;
    color: #000;
    font-size: 3em;
    margin-bottom: 40px;
  }
</style>
