<script>
  import { onMount } from "svelte"
  import MediaUploader from "./MediaUploader.svelte"
  import RichText from "./RichText.svelte"
  import Seminaries from "./Seminaries.svelte"
  import { apiKey, page } from "./store.js"
  import { acceptedMimetypes, datetimeForInput } from "./utils.js"

  const id = $page.data?.seminaryId
  let expiresAt
  let title
  let subtitle
  let cover
  let googleFormId
  let text
  let info

  let openMediaUploader

  onMount(async () => {
    try {
      if (id) {
        const res = await fetch("API_URL/seminaries/" + id)
        if (!res.ok) throw await res.json()
        const seminary = await res.json()

        expiresAt = seminary.expiresAt
          ? datetimeForInput(seminary.expiresAt)
          : null
        title = seminary.title
        subtitle = seminary.subtitle
        cover = seminary.cover
        googleFormId = seminary.googleFormId
        text = seminary.text
        info = seminary.info
      }
    } catch (error) {
      alert(error.message)
    }
  })

  function handleCoverMedia(mediaUrl) {
    cover = mediaUrl
  }

  async function submit() {
    if (!title) return alert("Le titre est requis")

    try {
      const res = await fetch(`API_URL/seminaries${id ? "/" + id : ""}`, {
        method: id ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify({
          expiresAt: expiresAt ? new Date(expiresAt) : null,
          title,
          subtitle,
          cover,
          googleFormId,
          text,
          info,
        }),
      })
      if (!res.ok) throw await res.json()
      $page = { component: Seminaries }
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>
  {#if id}Modification d'un séminaire{:else}Nouveau séminaire{/if}
</h1>

<label>
  Titre<br />
  <input bind:value={title} />
</label>

<label>
  Sous-titre<br />
  <input bind:value={subtitle} />
</label>

<label>
  Expire le<br />
  <input type="datetime-local" bind:value={expiresAt} />
</label>

<label>
  Couverture<br />
  {#if cover}
    <img src={cover} loading="lazy" height="200" alt="Couverture" />
  {/if}
  <button
    on:click={openMediaUploader(handleCoverMedia, acceptedMimetypes(true))}
  >
    Sélectionner
  </button>
</label>

<label>
  ID du formulaire Google<br />
  <input bind:value={googleFormId} />
</label>

<label for="text">
  Texte<br />
  <RichText bind:value={text} />
</label>

<label for="info">
  Informations pratiques<br />
  <RichText bind:value={info} />
</label>

<hr />

<button on:click={submit}>
  {id ? "✎ Modifier le séminaire" : "+ Ajouter le séminaire"}
</button>

<MediaUploader bind:open={openMediaUploader} />
