<script>
  import { onMount } from "svelte"
  import Sortable from "svelte-sortablejs"
  import MediaUploader from "./MediaUploader.svelte"
  import { apiKey } from "./store.js"
  import { acceptedMimetypes } from "./utils"

  let homeNews = []

  let openMediaUploader

  onMount(async () => {
    const res = await fetch("API_URL/home-news")
    if (!res.ok) throw await res.json()
    homeNews = await res.json()
  })

  let updateDelay
  function updateLink(item, link) {
    clearTimeout(updateDelay)
    updateDelay = setTimeout(async () => {
      homeNews = homeNews.map((hn) => {
        if (hn.media === item.media) hn.link = link
        return hn
      })
      await update()
    }, 1000)
  }

  async function update() {
    try {
      const res = await fetch("API_URL/home-news", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + $apiKey,
        },
        body: JSON.stringify(homeNews),
      })
      if (!res.ok) throw await res.json()
    } catch (error) {
      alert(error.message)
    }
  }

  async function remove(item) {
    homeNews = homeNews.filter((hn) => hn.media != item.media)
    await update()
  }

  function handleNew(mediaUrl) {
    for (let i = 0; i < homeNews.length; i++) {
      const item = homeNews[i]
      if (item.media === mediaUrl)
        return alert("Cette actualité est déjà dans la liste")
    }
    homeNews = [...homeNews, { media: mediaUrl }]
    update()
  }
</script>

<h1>À la une</h1>

<div class="my">
  <button on:click={openMediaUploader(handleNew, acceptedMimetypes(true))}>
    + Nouvelle annonce
  </button>
</div>

<ul class="basic sortable">
  <Sortable bind:list={homeNews} options={{ onEnd: update }}>
    {#each homeNews as item}
      <li data-media={item.media}>
        <img src={item.media} alt="Média" height="150" />
        <input
          value={item.link || null}
          on:input={(e) => updateLink(item, e.target.value)}
          placeholder="Lien vers l'actualité"
        />
        <button class="danger" on:click={remove(item)}>×</button>
      </li>
    {/each}
  </Sortable>
</ul>

<MediaUploader bind:open={openMediaUploader} />
