<script>
  import { onMount } from "svelte"
  import RecensionForm from "./RecensionForm.svelte"
  import { apiKey, page } from "./store.js"

  let recensions = []

  onMount(async () => {
    try {
      const res = await fetch("API_URL/recensions", {
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      recensions = await res.json()
    } catch (error) {
      alert(error.message)
    }
  })

  async function destroy(recension) {
    if (
      !confirm(
        `Voulez-vous vraiment supprimer la recension "${recension.title}" ?`
      )
    )
      return

    try {
      const res = await fetch("API_URL/recensions/" + recension.id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      recensions = recensions.filter((el) => el.id !== recension.id)
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>Recensions</h1>

<div class="my">
  <button on:click={() => ($page = { component: RecensionForm })}>
    + Ajouter une recension
  </button>
</div>

<table class="my">
  <thead>
    <tr>
      <th>Titre</th>
      <th>Publiée le</th>
      <th />
    </tr>
  </thead>
  <tbody>
    {#each recensions as recension}
      <tr>
        <td>{recension.title}</td>
        <td class:pending={new Date(recension.publishedAt) > new Date()}>
          {new Date(recension.publishedAt).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </td>
        <td>
          <button
            class="secondary"
            on:click={() =>
              ($page = {
                component: RecensionForm,
                data: { recensionId: recension.id },
              })}
          >
            ✎ Modifier
          </button>
          <button class="danger" on:click={() => destroy(recension)}>×</button>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
