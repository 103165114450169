<script>
  import { onMount } from "svelte"
  import SaturdayLessonForm from "./SaturdayLessonForm.svelte"
  import { apiKey, page } from "./store.js"

  let saturdayLessons = []

  onMount(async () => {
    try {
      const res = await fetch("API_URL/saturday-lessons")
      if (!res.ok) throw await res.json()
      saturdayLessons = await res.json()
    } catch (error) {
      alert(error.message)
    }
  })

  async function destroy(saturdayLesson) {
    if (
      !confirm(
        `Voulez-vous vraiment supprimer le cours du samedi "${saturdayLesson.title}" ?`
      )
    )
      return

    try {
      const res = await fetch("API_URL/saturday-lessons/" + saturdayLesson.id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
      })
      if (!res.ok) throw await res.json()
      saturdayLessons = saturdayLessons.filter(
        (el) => el.id !== saturdayLesson.id
      )
    } catch (error) {
      alert(error.message)
    }
  }
</script>

<h1>Cours du samedi</h1>

<div class="my">
  <button on:click={() => ($page = { component: SaturdayLessonForm })}>
    + Ajouter un cours du samedi
  </button>
</div>

<table class="my">
  <thead>
    <tr>
      <th>Titre</th>
      <th>Sous-titre</th>
      <th>Premier cours</th>
      <th>Dernier cours</th>
      <th>Date de l'examen</th>
      <th />
    </tr>
  </thead>
  <tbody>
    {#each saturdayLessons as saturdayLesson}
      <tr>
        <td>{saturdayLesson.title}</td>
        <td>
          {#if saturdayLesson.subtitle}
            {saturdayLesson.subtitle}
          {:else}
            —
          {/if}
        </td>
        <td class:pending={new Date(saturdayLesson.firstLesson) > new Date()}>
          {new Date(saturdayLesson.firstLesson).toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </td>
        <td class:pending={new Date(saturdayLesson.lastLesson) > new Date()}>
          {#if saturdayLesson.lastLesson}
            {new Date(saturdayLesson.lastLesson).toLocaleDateString("fr", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          {:else}
            —
          {/if}
        </td>
        <td class:pending={new Date(saturdayLesson.exam) > new Date()}>
          {#if saturdayLesson.exam}
            {new Date(saturdayLesson.exam).toLocaleDateString("fr", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          {:else}
            —
          {/if}
        </td>
        <td>
          <button
            class="secondary"
            on:click={() =>
              ($page = {
                component: SaturdayLessonForm,
                data: { saturdayLessonId: saturdayLesson.id },
              })}
          >
            ✎ Modifier
          </button>
          <button class="danger" on:click={() => destroy(saturdayLesson)}
            >×</button
          >
        </td>
      </tr>
    {/each}
  </tbody>
</table>
