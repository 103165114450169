<script>
  import { DialogContent, DialogOverlay } from "svelte-accessible-dialog"
  import { apiKey } from "./store.js"

  let handler // Receives media URL
  let isOpen
  let accept = []
  let files
  let insideDropzone = false

  export function open(parentHandler, acceptedMimetypes = []) {
    handler = parentHandler
    accept = acceptedMimetypes
    isOpen = true
  }

  const close = () => {
    isOpen = false
  }

  function fileDrop(e) {
    const item = e.dataTransfer.items[0]
    if (item.kind !== "file") {
      insideDropzone = false
      return
    }
    const file = item.getAsFile()
    if (!accept.includes(file.type))
      return alert("Ce type de fichier n'est pas accepté pour ce champ")
    files = [file]
    upload()
  }

  async function upload() {
    const formData = new FormData()
    formData.append("file", files[0])

    try {
      const res = await fetch("API_URL/medias", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + $apiKey,
        },
        body: formData,
      })
      if (!res.ok) throw await res.json()
      const mediaUrl = await res.text()
      send(mediaUrl)
    } catch (error) {
      alert(error.message)
    }
  }

  function send(mediaUrl) {
    handler(mediaUrl)
    close()
  }
</script>

<DialogOverlay {isOpen} onDismiss={close}>
  <DialogContent>
    <button on:click={close}>× Fermer</button>
    <h1>Sélectionner un média</h1>
    <label
      id="dropzone"
      class:insideDropzone
      on:drop|preventDefault={fileDrop}
      on:dragover|preventDefault={() => {
        insideDropzone = true
      }}
      on:dragleave|preventDefault={() => {
        insideDropzone = false
      }}
    >
      Glissez un fichier ici
      <input
        type="file"
        accept={accept.join(",")}
        bind:files
        on:change={upload}
      />
    </label>
  </DialogContent>
</DialogOverlay>

<style>
  #dropzone {
    align-items: center;
    border-radius: 8px;
    border: 2px dashed black;
    cursor: pointer;
    display: flex;
    font-size: 1.5em;
    font-weight: 300;
    justify-content: center;
    min-height: 50vh;
    padding: 50px;
    margin-bottom: 0;
  }

  #dropzone input {
    display: none;
  }

  #dropzone.insideDropzone {
    background: #000;
    color: white;
    border-style: solid;
  }
</style>
